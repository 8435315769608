// 创建文件en/index.js
const en = {
  system: "AIGC PLATFORM",
  WebName:"AIGC PLATFORM",
  Demo: "Demo",
  theme: "Video 2 Video",
  TipsText:
    "Beijing, the capital of ‌ People's Republic of China, is located in north China. It is a famous cultural city with a history of more than 3000 years.",

  firstIndex: "Home",
  language: "Chinese",
  Error: {
    network: "The network connection is down. Please try again later",
    NoneTigs: "No task",
    NoneDownFile:"No resources can be saved",
    EnterCopyFile:"Please enter the text you want to copy",
     exchangeFile: "Please enter the text you want to convert first",
         inputName: "Please fill in the name first",
    uploadFile: "Please upload or record the file first",
    FailNetwork: "Request failed",
     limitCount: "Files are uploaded not more than 5 times"
    
  },
  DialogModel: {
    PublicMediaLibrary: "Public Media Library",
    ChooseTitle: "Choose",
    Cancel: "Cancel",
    Sumit: "Confirm",
    Userdefined: "User Defined",
    Preview: "Preview",
    CancelPreview: "Cancel Preview",
    exchangeFileSuccess: "If the conversion is successful, you can listen and save",
  },
  FileAction: {
    Cancel: "Delete",
    Download: "Download",
    Loading: "Loading",
    incorrectType: "The uploaded file format is incorrect",
    limitSize: "Upload file size cannot exceed 200MB!",
    AllowFile:
      "Only PDF, DOC, DOCX, TXT, image, Excel or CSV files are allowed to be uploaded",
    loginTips: "Login in first",
    TipsTitle: "Tips",
    deleteSuccess: "Successfully deleted",
    failDeleted: "Deletion failure",
    cancelDelete: "Undelete",
    delete: "Delete",
    SaveSuccess:"Successfully save",
    RequestFailed:"Request Failed",
    ConfirmationPrompt: "Make sure you want to delete the file",
    CustomScene: "Custom Scene",
    UploadScene: "Upload your scene",
     CopySuccess: "Successfully copy"
  },
  Setting: {
    Profile: "Profile",
    Password: "Password",
  },
  Price: {
    Subscription: "Subscription",
    Free: "Free",
    Pro: "Pro",
    EnterPrice: "Enter Price",
  },
  topMenu: {
    menu01: "product",
    menu02: "introduction",
    menu03: "Price",
  },
  login: "login",
  register: "register",
  siderMenu: {
    Video2Video: "Video2Video",
    AIWritting: "AI Writting",
    AIvoice: "AI Voice",
    AICharacter: "AI Character",
    VideoMake: "Video2Video",
    Customized: "Customized",
    AIAvatar: "AI Avatar",
  },
  AIWritting: {
    title: "AI Chat",
    modelSelect: "Model Selection",
    select: "Select",
    inputText: "Input Text",
    send: "Send",
    firstwords: "Hello, can I help you?",
  },
  AIVoice: {
    ThemeTitle: "AI Voice",
    TexttoSpeech: "Text-to-Speech",
    VoiceSelection: "Voice Selection",
    NoneTigs: "No, please go to the right side to customize the clone",
    CustomVoice: "Custom Voice",
    SelectVoice: "Select Appropriate Voice",
    EnterContent: "Please Enter Content",
    ClickConversion: "One-click Conversion",
    Audition: "Audition",
    Listening: "Listening",
    Download: "Download",
    CloudStorage: "Cloud Storage",
    CloneVoice: "Clone Voice",
    GiveName: "Please give the file a name first",
    UploadFile: "Upload Video or Voice",
    RapidCloning: "Rapid Cloning",
    SelectTextListen: "Select text first and then listen",
    Samples: "Voice Clonning Samples",
    Male: "Male",
    Female: "Female",
    AnalysisAndTranslation: "Video Speech Analysis and Translation",
    SpeechToText: "Speech-to-Text",
    HereSpeechToText: "Here's speech to text",
    Copy: "One-click Replication",
    TranslatedText: "Translated Text",
    UploadVoiceBtn: "Upload Voice",
    OneselfVoice: "Cloned Oneself Voice",
    Preview: "Preview",
    RandomBtn: "Random Cases",
    SaveTips: "Whether the generated audio is satisfactory or not?",
    saveBtn: "Save",
    cancelBtn: "Cancel",
    UploadTextTigs: "Upload a voice or video",
    defaultText:
      "Beijing, the capital of ‌ People's Republic of China, is located in north China. It is a famous cultural city with a history of more than 3000 years.",
  },

  AICharacter: {
    title: "Pic 2 Video",
    AvatarTitle: "AI Avatar",
    AiAudio: "AI Audio",
    ImportVoice: "Import Voice",
    UploadVoice: "Upload Voice",
    UploadVoiceText: "Upload Your Voice",
    FaceText: "Upload a picture with a face",
    PlaceholderText: "Please select an appropriate voice",
    EmptyTips: "No audio is available , Customize the audio first",
    SelectAvatar: "Select Avatar",
    CustomAvatar: "Custom Avatar",
    ClickBtn: "Click to Select",
    ShowTips:
      "Limited to 5 second videos, you can use it to customize longer voice videos in ",
    GenerationClick: "One-Click Generation",
    DeleteFile: "Delete",
    DownLoadFile: "DownLoad",
    modelName: " 'Video2Video' Model",
  },

  AIVideoModel: {
    title: "Video 2 Video",
    AiAudio: "AI Audio",
    ImportVoice: "Import Voice",
    UploadVoice: "Upload Voice",
    SelectVideo: "Select Video",
    CustomizedVideo: "Customized Video",
    chooseBtn: "Click to Select",
    UploadText: "Upload a voice messag",
    UploadVideoText: "Upload a video messag",

    ShowTips:
      "Your video will be displayed here, please click the button below to do so Generate",
    GenerationClick: "One-Click Generation",
    DeleteFile: "Delete",
    DownLoadFile: "DownLoad",
    SaveFileBtn: "Cloud Save",
  },

  LoginModel: {
    EmailLogin: "Email Login",
    PhoneLogin: "Mobile Login",
    UsernameOrEmail: "User or Email",
    password: "Password",
    Submit: "Submit",
    Reset: "Reset",
    PhoneNumber: "Phone Number",
    VerificationCode: "Verification Code",
    Sendcode: "Send Code",
    GoRegister: "To Register",
    Forgotpassword: "Forgot Password",
    Goback: "Back to Home",
    passwordTigs: "Please enter password",
    phoneTigs: "Please  enter your phone number",
    codeTigs: "Please enter the verification code",
    userTigs: "Please enter your username",
    sendTigs: "Send Failure",
  },

  RegisterModel: {
    EmailRegister: "Email Registration",
    placeholderText: "Please enter email",
    Username: "Username",
    password: "Password",
    PhoneRegister: "Mobile number registration",
    PhoneNumber: "Phone Number",
    VerificationCode: "Verification Code",
    Sendcode: "Send Code",
    Register: "Register",
    Reset: "Reset",
    GoLogin: "Go Login",
    Goback: "Back to Home",
    CorrectEmailFormat: "Please enter the correct email format",
    passwordTigs: "Please enter password",
    phoneTigs: "Please  enter your phone number",
    codeTigs: "Please enter the verification code",
    userTigs: "Please enter your username",
    sendTigs: "Send Failure",
    successTigs: "Registered Successfully!",
  },

  FindPassModel: {
    themeTitle: "Password",
    Email: "Email",
    SendCode: "Send Code",
    VerificationCode: "Verification Code",
    NewPassword: "New Password",
    ConfirmPassword: "Confirm Password",
    Submit: "Submit",
    Reset: "Reset",
    EnterEmailText: "Please enter email",
    CorrectEmailFormat: "Please enter the correct email format",
    passwordTigs: "Please enter password",
    NewPasswordTigs: "Please enter your confirmation password",
    codeTigs: "Please enter the verification code",
    GoLogin: "Go Login",
    Goback: "Back to Home",
    SuccessfulSetting: "The Settings are successful. Log in again",
  },

  MediaLibrary: {
    themeTitle: "User Media Library",
    AudioData: "Audio Data",
    VideoData: "Video Data",
    PictureData: "Picture Data",
  },

  full: "full-screen display",
  account: "myAccount",
  invoice: "invoice",
  reconciliation: "Statement",
  record: "recording",
  report: "report",
  setting: "Settings",

  tips: "{name} Username and password are filled in casually {cont}",
  administrator: "administrator",
  placeUser: "please enter user name",
  palcePass: "Please enter your password",
  palceCode: "please enter verification code",
  accounts: "accounts",
  password: "password",
  code: "Verification code",
  footer: {
    CompanyProfile: "Company Profile",
    introduction: "Company Introduction",
    UserAgreement: "User Agreement",
    Privacy: "Privacy Agreement",
    Aboutus: "About Us",
    ContactUs: "Contact Us",
    CustomerService: "Customer Service",
    TechnicalSupport: "Technical Support",
  },

  Table: {
    View: "View",
    Download: "Download",
    Delete: "Delete",
    Preview: "Preview",
    Time: "Time",
     label: "Item",
     Operation: "Operation",
    ImageToVideoTitle: "Tucson Video Recording",
    VideoHistoryTitle: "Video Generation Recording",
    ImageHistoryTitle: "Picture Management",
    VoiceCloneHistoryTitle: "Cloned Voice Data",
    VoiceName: "Name",
    TTSSpeech: "TTS",
    Playing: "Playing",
    Play: "Play",
    NoMoreData:"No More Data"
  },
  Company: {
    title: "Company Introduction:",
    desc01: "Kamikasi (Taicang) Technology Co., Ltd. is a leading technology startup in Suzhou and Taicang, recognized as a Jiangsu Province Science and Technology Small and Medium-sized Enterprise and a Jiangsu Private Technology Enterprise. It is also an official member of the NVIDIA Inception Startup Accelerator Program. Founded in July 2022 by veteran film producer Gu Xing, senior AI expert Chen Ping, and other team members, Kamikasi focuses on the research and development, as well as industrialization, of specialized digital humans and AIGC (Artificial Intelligence Generated Content) for the media production industry.",
    desc02: "The company's primary business revolves around nurturing and creating AIGC IPs and digital human idol artists. Its auxiliary business provides services for the creation, application, and operation of interactive digital humans to enterprises and individuals. Leveraging cutting-edge AI technology, Kamikasi has achieved significant milestones in developing highly realistic and intelligent digital humans that can perform autonomously or be controlled remotely, opening up new possibilities in entertainment, education, and more.",
    desc03: "With a vision to become a leading developer and operator of AI digital humans in the world, Kamikasi has studied and deployed over Three hundred AI models and continues to push the boundaries of innovation, enhancing the integration of AI with creative industries and delivering unparalleled experiences to users."
  }
};
export default en;
