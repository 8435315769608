import Vue from "vue";
import Router from "vue-router";
import Layout from "../layouts/LayoutsVue.vue";
import MobileLayout from '../layouts/MobileLayouts.vue'

import i18n from "../../src/i18n";


Vue.use(Router);

// 重写 push replace方法
const originalPush = Router.prototype.push;

let originReplace = Router.prototype.replace;

//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Router.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch((err) => err);
};

const traditionalRoutes = [
  // {
  // path: "/",
  // redirect: "/system", // 重定向到 /tool
  // },
  // 登录
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login/index"),
    meta: { title: "登录", icon: "table" ,titleKey:'login'},
  },
  //注册
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/Register/index.vue"),
    meta: { title: "注册", icon: "table" ,titleKey:"register"},
  },

  {
    path: "/password/find",
    name: "passwordfind",
    component: () => import("@/views/Password/find.vue"),
    meta: { title: "找回密码", icon: "table" , titleKey:"password"},
  },
];

const MobileRoutes = [
  {
    path: "/mobile/login",
    name: "mobileLogin",
    component: () => import("@/views/MobileTool/Login/LoginIndex.vue"),
    meta: { title: "登录", icon: "table" ,titleKey:'login'},
  },
  {
    path: "/mobile/index",
    name: 'LayoutIndex',
    component: MobileLayout,
    children: [
      {
        path: "/mobile/index",
        name: "mobileindex",
        component: () => import("../views/MobileTool/Home/HomeIndex.vue"),
        meta: { title: "首页", icon: "table" ,titleKey:"firstIndex"}
      }
    ]
    
   
   
  }


];

// 定义工具网站系统路由

// {
// path: '/',
// component: Layout,
// children: [
// {
// path: "/",
// name: "Pipleline",
// component: () => import("@/views/Tool/pipeLine.vue"),
// meta: { title: "工具网站", icon: "documentation" },
// },
// ]
//
// },

const ToolSystemRoutes = [
  {
    path: "/",
    component: Layout,
    // redirect: "/system/pipleline",
    name: "Example",
    // meta: { title: "Example", icon: "el-icon-s-help" },
    children: [
      {
        path: "/",
        name: "Pipleline",
        component: () => import("@/views/Tool/pipeLine.vue"),
        meta: { title: "工具网站", icon: "table" , titleKey:"WebName"},
      },
      {
        path: "/price",
        name: "Price",
        component: () => import("@/views/Tool/PriceVue.vue"),
        meta: { title: "价格", icon: "tree" , titleKey:"topMenu.menu03"},
      },
      {
        path: "/about/kmks",
        name: "aboutkmks",
        component: () => import("@/views/About/kmks"),
      },
    ],
  },

  {
    path: "/talk",
    component: Layout,
    children: [
      {
        path: "/talk",
        name: "talk",
        component: () => import("@/views/Tool/ChatVue.vue"),
        meta: { title: "AI 文本", icon: "documentation", keepAlive: true ,  titleKey:"siderMenu.AIWritting",},
      },
    ],
  },

  {
    path: "/highlevel",
    component: Layout,
    children: [
      {
        path: "/highlevel",
        name: "highlevel",
        component: () => import("@/views/Tool/HighLevel/HighLevel.vue"),
        meta: { title: "高级定制", icon: "documentation" },
      },
    ],
  },
  {
    path: "/voice",
    component: Layout,
    children: [
      {
        path: "/voice",
        name: "VoiceMake",
        component: () => import("@/views/Tool/Voice/VoiceMake.vue"),
        meta: { title: "AI语音", keepAlive: true ,  titleKey:"siderMenu.AIvoice",},
      },
    ],
  },
  {
    path: "/video",
    component: Layout,
    redirect: "/video/avatarmake",
    name: "Example",
    meta: { title: "Example", icon: "el-icon-s-help" },
    children: [
      {
        path: "/video/avatarmake",
        name: "avatarmake",
        component: () => import("@/views/Tool/Video/AvatarMake.vue"),
        meta: { title: "AI人物", icon: "table", keepAlive: true , titleKey:"siderMenu.AICharacter"},
      },
      {
        path: "/video/customavatar",
        name: "customavatar",
        component: () => import("@/views/Tool/CustomMade/UsercustomAvatar.vue"),
        meta: { title: "Avatar制作", icon: "table", keepAlive: true ,  titleKey:"siderMenu.AIAvatar"},
      },
      {
        path: "/video/aimake",
        name: "aimake",
        component: () => import("@/views/Tool/Video/AiModuleMake.vue"),
        meta: { title: "AI视频模块制作", icon: "tree", keepAlive: true, titleKey:"siderMenu.VideoMake" },
      },
      {
        path: "/video/translate",
        name: "translate",
        component: () => import("@/views/Tool/Video/VideoTranslate.vue"),
        meta: { title: "视频翻译", icon: "tree", keepAlive: true },
      },
    ],
  },

  {
    path: "/user",
    component: Layout,
    redirect: "/user/voice",
    name: "用户媒体库",
    meta: { title: "Example", icon: "el-icon-s-help" ,titleKey:"MediaLibrary.themeTitle"},
    children: [
      {
        path: "/user/voice",
        name: "uservoice",
        component: () => import("@/views/Tool/UserManage/UserVoice.vue"),
        meta: { title: "音频数据", icon: "table", authRequired: true ,  titleKey:"MediaLibrary.AudioData"},
      },
      {
        path: "/user/video",
        name: "uservideo",
        component: () => import("@/views/Tool/UserManage/UserVideo.vue"),
        meta: {
          title: "视频数据",
          titleKey:"MediaLibrary.VideoData",
          icon: "table",
          keepAlive: true,
          authRequired: true,
        },
      },
      {
        path: "/user/image",
        name: "userimage",
        component: () => import("@/views/Tool/UserManage/UserImage.vue"),
        meta: { title: "图片数据", icon: "table", authRequired: true,titleKey:"MediaLibrary.PictureData" },
      }
    ],
  },
];

const router = new Router({
  mode: "history",
  routes: [...traditionalRoutes, ...MobileRoutes, ...ToolSystemRoutes ],
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    if (to.meta.titleKey) {
      document.title = i18n.t(to.meta.titleKey);
    } else {
      document.title = to.meta.title;
    }
    
  }
  next();
});

export default router;
