<template>
  <div class="mobile-layout-box">
    <div class="top-box">
      <TopNavBar />
    </div>
    <div class="mobile-content">
      <keep-alive>
        <router-view :key="$route.path"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import TopNavBar from "@/views/MobileTool/Comps/TopNavBar.vue";

export default {
  components: {
    TopNavBar,
  },
  data() {
    return {};
  },
};
</script>
