<template>
  <el-menu
    default-active="2"
    class="el-menu-vertical-demo MobileMenuStyle"
    @open="handleOpen"
    @close="handleClose"
   
    text-color="#000"
    :active-text-color="activeTextColor"
    :collapse="isCollapse"
    
  >
    <template v-for="(item, index) in MobileMenuList">
      <el-submenu :index="item.link" :key="index" v-if="item.children">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span>{{ $TranslatedMessage(item.titleKey) }}</span>
        </template>
        <template v-for="submenu in item.children">
          <el-menu-item :index="submenu.link" :key="submenu.link">{{
            $TranslatedMessage(submenu.titleKey)
          }}</el-menu-item>
        </template>
      </el-submenu>
      <el-menu-item
        v-if="!item.children"
        :index="item.link"
        :key="item.link"
        :route="{ path: item.link }"
      >
        <i :id="item.elementId" class="el-icon-menu"></i>
        <span>{{ $TranslatedMessage(item.titleKey) }}</span>
      </el-menu-item>
    </template>

    <template v-for="item in MobileUserMenuList">
      <el-submenu :index="item.link" :key="item.link" class="bottomSubmenu">
        <template slot="title">
          <i class="el-icon-user-solid"></i>
          <span slot="title">{{ $TranslatedMessage(item.titleKey) }}</span>
        </template>
        <el-menu-item
          :index="menu.link"
          :key="menu.link"
          v-for="menu in item.children"
          >{{ $TranslatedMessage(menu.titleKey) }}</el-menu-item
        >
      </el-submenu>
    </template>
  </el-menu>
</template>

<script>
import { UserLibraryMenuList, ToolMenuList } from "@/utils/GlobalData";
export default {
  data() {
    return {
      activeTextColor: "#ffd04b",
      windowName: "myAppWindow", // 设置一个固定的窗口名称
      active: "",
      isCollapse: false,
      openSubmenus: ["/video", "/custommade"],
      MobileUserMenuList: UserLibraryMenuList,
      MobileMenuList: ToolMenuList,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style scoped>
.MobileMenuStyle {
  width: 100%;
}

.MobileMenuStyle >>> .el-submenu__title {
  text-align: left;
}
</style>
