<template>
  <div class="top-nav-bar">
    <div class="nav-box">
      <div class="nav-left">
        <div class="left-icon" @click="showMenu = !showMenu">
          <van-icon name="list-switching" v-if="!showMenu" />
          <van-icon name="close" v-else />
        </div>
      </div>
      <div class="nav-middle">kamikasi</div>
      <div class="nav-right" @click="$router.push('/login')">登录</div>
    </div>

    <div class="popMenuBox" v-show="showMenu">
     <MenuBar/>

    </div>
  </div>
</template>

<script>

import MenuBar from './MenuBar.vue';


export default {

  components: {
    MenuBar
  },
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.nav-box {
  background-color: #25292e;

  padding:  15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  .left-icon {
    font-size: 30px;
  }

  .nav-middle {
    width: 50%;
  }
}

.popMenuBox {
    width: 80%;
    height: 100%;
    position: absolute;
    background-color: #fff;
    padding: 15px 0;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .2);
}
</style>
